import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
  import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input, Button, Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logokpru.png'

import Swal from 'sweetalert2'
import Modalregisterauthorities from './register/Modalregisterauthorities';
import Modallogin from './register/Modallogin';



export default class Homepage extends Component {
    
    //    state = {
    //       Username: "",
    //       Password: "",
    //       loading: true,
    //       login_error: true,
    //       login_error2: true,
    //       login_error3: true,
    //     }

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.toggle = this.toggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.register = this.register.bind(this);
            this.registerauthor = this.registerauthor.bind(this);
            
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          register() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerstudent'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           registerauthor() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerauthorities'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

          handleSubmit(event) {
            // alert('A name was submitted: ' + this.state.value);
             event.preventDefault();

            const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
         datafrm.append("txtemail", this.state.value);
         datafrm.append("txtpass", this.state.Psaaword);

         
         const user = {
          "txtemail": this.state.value,
          "txtpass": this.state.Psaaword
        };

         fetch('https://mua.kpru.ac.th/FrontEnd_Mis/login/login/', {
          method: 'POST',
          body: JSON.stringify(user),
          headers: {
        
          }
        })
          .then(res => res.json())
          .then((res) => {
              console.log(res);
              if (res[0].loginstatus === "1" ) {

           
                //  alert("เข้าสู่ระบบสำเร็จ")

                   localStorage.setItem('name', res[0].frist_name +"  "+ res[0].last_name);
      
                   message.success("เข้าสู่ระบบสำเร็จ")
                   localStorage.setItem('loginstatus', res[0].loginstatus);
                   localStorage.setItem('type2', res[0].employee_id);
                   localStorage.setItem('admin_type', 2);
                 
              //   window.location.href = '/components/administrator/reportall'
             // window.location.href = 'https://conference.kpru.ac.th/adminconference-sd/adminfaculty'
            //  window.location.href = 'https://e-student.kpru.ac.th/grade/adminfaculty'
              window.location.href = '/vaccinekpru/Stuall'
              }else{
                 // message.error("เข้าสู่ระบบไม่สำเร็จ")
                  


                 Swal.fire(
                  'ไม่สามารถเข้าถึงระบบได้',
                  'กรุณาลองอีกครั้ง',
                  'question'
                )
                //  window.location.href = '/'
              }
            
          })
          .catch(err => {
            console.log('request failed', err);
          })
         

          }
      
       



    update = () => {
        this.setState({
            name: "",
            lname: ""
        })
    }

    render() {
      
        return (
       
                <MDBContainer >
           
           <MDBCarousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(136)-mini.webp"
                  alt="First slide"
                />
                <MDBMask overlay="black-light" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(137)-mini.webp"
                  alt="Second slide"
                />
                <MDBMask overlay="black-strong" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(141)-mini.webp"
                  alt="Third slide"
                />
                <MDBMask overlay="black-slight" />
              </MDBView>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>

                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
        <MDBModalHeader toggle={this.toggle}>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
         
        <MDBIcon icon="exclamation-circle" className="red-text text-center" size="5x" />
         <h3>usermame หรือ password ไม่ถูกต้อง</h3>
         <h3>กรุณาตรวจสอบความถูกต้อง อีกครั้ง!</h3>
        </MDBModalBody>
       
      </MDBModal>

<br/>

<MDBTable>
      <MDBTableHead>
        <tr>
          <th>#</th>
          <th>First</th>
          <th>Last</th>
          <th>Handle</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>Otto</td>
          <td>@mdo</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>Thornton</td>
          <td>@fat</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Larry</td>
          <td>the Bird</td>
          <td>@twitter</td>
        </tr>
      </MDBTableBody>
    </MDBTable>
{/* <h2 className="text-center"> <MDBIcon icon="running" /> ลงทะเบียน</h2> */}
      <MDBRow >
        
        <MDBCol md="6" className="d-flex justify-content-center">
        <MDBCard style={{ width: "28rem", marginTop: "1rem" }}>
    <MDBCardBody>
      <MDBCardTitle className="text-center">สำหรับนักศึกษา</MDBCardTitle>
        <div className="text-center">
        <MDBIcon icon="user-tie" size="4x" className="text-center"/>
        </div> 
      
      <MDBCardText>
        With supporting text below as a natural lead-in to additional content.
      </MDBCardText>
      <div className="text-center">
        <MDBBtn color="deep-orange" onClick={this.register} >ลงทะเบียน</MDBBtn>
      </div>
      
    </MDBCardBody>
  </MDBCard>
        </MDBCol>


        <MDBCol md="6" className="d-flex justify-content-center">
        <MDBCard style={{ width: "28rem", marginTop: "1rem" }}>
    <MDBCardBody>
      <MDBCardTitle className="text-center">สำหรับเจ้าหน้าที่/บุคคลทั่วไป</MDBCardTitle>
      <div className="text-center">
        <MDBIcon icon="user" size="4x"/>
      </div>
      
      <MDBCardText>
        With supporting text below as a natural lead-in to additional content.
      </MDBCardText>
      <div className="text-center">
        {/* <Modalregisterauthorities/> */}
        <MDBBtn color="deep-orange" onClick={this.registerauthor} >ลงทะเบียน</MDBBtn>
      </div>
      
      {/* <MDBBtn color="primary">ลงทะเบียน</MDBBtn> */}
    </MDBCardBody>
  </MDBCard>
        </MDBCol>
      </MDBRow>

<br/>
      <MDBRow>
        <MDBCol md="4"></MDBCol>
        <MDBCol md="4" className="d-flex justify-content-center">
        <div className="text-center">
          <Modallogin />
        </div>
          </MDBCol>
        <MDBCol md="4"></MDBCol>
     </MDBRow>

                   
                    </MDBContainer>
                   
                   
        )
    }
}
