import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'

export default class Modalbanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            namebanner: '',
            fileupload: '',

            name_workorder: '',
            worklistdata: [],
           
            filedesing_workorder: '',

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
       
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
        const date = new Date().toISOString().slice(0, 10)
       setTimeout(() => {
    
          const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
          datafrm.append("namebanner", this.state.namebanner);
          datafrm.append("banner_file", this.state.fileupload);
          datafrm.append("banner_datesent", date);
          datafrm.append("banner_status", 1);
          //datafrm.append("id_workorder", this.state.id_workorder);
        
          api.post("updatefilebanner.php",datafrm,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
    
       .then(res => {
        if (res.status=== 200) {
            Swal.fire({
                title: 'บันทึกแบนเนอร์สำเร็จ',
                icon: 'success',
              // showCancelButton: true,
                confirmButtonText: 'ตกลง',
              // cancelButtonText: 'No, keep it'
              }).then((result) => {
                this.toggle();  
            //  this.getworklist();
                this.props.getbanner();
              })
        }else{
          message.error("อัพโหลไฟล์ไม่สำเร็จ!")
        }
    
      })
    
    
    }, 2000);
    
    //window.location.href = '/adminfaculty'
    //this.props.updateat();
    //this.getExpert()
     
      }

    render() {
   // console.log(this.state.namework)
 //   console.log(this.state.fileupload)
        return (
            <MDBContainer>
                <MDBBtn color="pink" onClick={this.toggle}>เพิ่มข้อมูล</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}>เพิ่มข้อมูล</MDBModalHeader>
                    <MDBModalBody>
                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-2">
                                <label className="font-weight-bold"><MDBIcon icon="bible" /> ชื่อแบนเนอร์</label>
                                <input className="form-control" onChange={this.onTextChange} name="namebanner"
                                    value={this.state.namebanner} placeholder="" required />
                            </div>
                            <br />
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="mb-2">
                                        <label className="font-weight-bold"><MDBIcon icon="paperclip" /> ไฟล์แบนเนอร์</label><br />
                                        {/* <MDBInput   group   type="file" validate error="wrong"
                                    success="right"  name="file" value={this.state.value} onChange={this.handleChange}/> */}

                                        {/* <input className="custom-file-input" type="file" onChange={this.handleChangefile} name="auth_agency" 
                                      value={this.state.auth_agency} placeholder="" required /> */}
                                        {/* <MDBInput  icon="file-upload" group   type="file" validate error="wrong"
                                        success="right"  name="file" accept="image/x-png,image/jpg,image/jpeg" value={this.state.value} onChange={this.handleChange} required/> */}
                                        <input type="file" id="img" name="fileupload" onChange={this.handleChangefile} accept="image/x-png,image/jpg,image/jpeg" ></input>
                                    </div>
                                </MDBCol>

                            </MDBRow>
                            {/* <Divider orientation="left" plain>
                            Left Text
                            </Divider> */}
                            {/* {this.state.filedesing_workorder == '' && this.state.filedesing_workorder == null ? <></> : <>
                                <MDBRow>
                                    <MDBCol>
                                        <img src={`https://lookmaenamping.com/sorlalumbackend/uploadsdesing/${this.state.filedesing_workorder}`} className="rounded mx-auto d-block " width="500" alt="aligment" />
                                    </MDBCol>
                                </MDBRow>
                            </>} */}


                            {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                            <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> บันทึก</MDBBtn>
                                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                            </div>


                        </form>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        )
    }
}
