import React, { Component } from 'react'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBFormInline, Link
} from "mdbreact";
import {  MDBContainer, MDBMask, MDBView } from 'mdbreact';
import { Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip } from 'antd';
import { URL } from '../api';



export default class Header extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      nameuser: '',
      admin_type: '',
      Username: '',
      collapse: false,
      isWideEnough: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  
    

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }



    componentDidMount() {
      //  const token = JSON.parse(localStorage.getItem('nameuser'));
        if (localStorage.getItem('name') !== "") {
          this.setState({ nameuser: localStorage.getItem('name') })
          this.setState({ admin_type: localStorage.getItem('admin_type') })
        } else {
    
            this.setState({ admin_type: 'public' })

        }

         // this.setState({ nameuser: localStorage.getItem('nameuser') })
         // this.setState({ admin_type: localStorage.getItem('admin_type') })
      }


      logout() {
      //   localStorage.removeItem(('admin_type'))
       //  localStorage.removeItem(('nameuser'))
        localStorage.clear();
    
        setTimeout(() => {
          window.location.href = '/'
        //  window.location.href = 'https://e-student.kpru.ac.th/vaccinekpru'
        }, 1000);
       // this.setState({ admin_type: 'public' }
        message.success("ออกจากระบบแล้ว")
      }



    render() {

    // console.log(this.state.admin_type) ;

        let logout
        logout = (
          <>
            <MDBNavItem>
              <MDBNavLink to="/main"> {this.state.nameuser}</MDBNavLink>
    
            </MDBNavItem>
           
            <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
    
        let superadmin
        superadmin = (
          <>
            {/* <MDBNavItem >
                 <MDBNavLink to="/Check"><MDBIcon icon="file-alt" /> หน้าหลัก</MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem >
                 <MDBNavLink to="/Stuall"><MDBIcon far icon="user-circle" /> นักศึกษา</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
                 <MDBNavLink to="/Personnelpage"><MDBIcon icon="user-circle" /> บุคลากร</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
                 <MDBNavLink to="/Report"><MDBIcon far icon="address-card" /> รายงานข้อมูลสถานะ</MDBNavLink>
            </MDBNavItem>
            {/* <MDBNavItem >
                 <MDBNavLink to="/Learn"><MDBIcon far icon="chart-bar" />ตารางเรียน</MDBNavLink>
            </MDBNavItem> */}
            {/* <MDBNavItem >
                 <MDBNavLink to="/Classroom"><MDBIcon far icon="chart-bar" />จัดการห้องเรียน</MDBNavLink>
            </MDBNavItem> */}
           


             <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/"><MDBIcon icon="sign-out-alt" />  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
        

        // let adminfaculty
        // adminfaculty = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfaculty"><MDBIcon icon="home" /> เมนู1</MDBNavLink>
        //     </MDBNavItem>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfacultyproportion"><MDBIcon icon="home" /> เมนู2</MDBNavLink>
        //     </MDBNavItem>
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )


        // let expert
        // expert = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> หน้าหลัก</MDBNavLink>
        //     </MDBNavItem>
        //     {/* <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> สัดส่วนบทความvvvvvv</MDBNavLink>
        //     </MDBNavItem> */}
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )
    


        let publics
        publics = (
          <>
            <MDBNavItem  >
              <MDBNavLink to="/">   รู้จักองค์กร </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   รายละเอียดสาขาวิชา </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   บุคลากร </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   วิจัยวิชาการ </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   ตารางออกตรวจผู้ป่วยนอก </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   การศึกษาก่อนปริญญา </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  >
              <MDBNavLink to="/">   การศึกษาหลังปริญญา </MDBNavLink>
            </MDBNavItem>
           
            {/* <MDBNavItem  >
              <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
            </MDBNavItem> */}
             <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          
          </>
        )

        const name = this.state.nameuser;

        return (
            <>
                <MDBNavbar color="danger-color" dark expand="md" >
                    <MDBNavbarBrand>
                    
                        <strong className="white-text"> ภาควิชาอายุรศาสตร์ โรงพยาบาลราชวิถี</strong>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={this.toggleCollapse} />
                    <MDBCollapse id="navbarCollapse3" onCollapse={(e)=>{console.log(e)}} isOpen={this.state.isOpen}  navbar>
                        <MDBNavbarNav left>

                            {/* <MDBNavItem >
                                <MDBNavLink to="/"><MDBIcon icon="home" /> Home</MDBNavLink>
                            </MDBNavItem> */}
                      {/* { localStorage.getItem('name') == null ? <>
                      {publics}
                     {window.location.href = '/tess'} 
                      </>:<>
                       {this.state.admin_type === '2' ? <>
                                      {superadmin}
                                      </> : null}
                      </>} */}
                                                
                                      {this.state.admin_type === '2' ? <>
                                      {superadmin}
                                      </> : null}

                                      {/* {this.state.admin_type === '1' ? <>
                                      {publics}
                                      </> : null} */}

                                      {/* {this.state.admin_type === '4' ? <>
                                      {adminfaculty}
                                      </> : null}   */}
                              

                        </MDBNavbarNav>
                        <MDBNavbarNav right>
                            <MDBNavItem>
                                <MDBFormInline waves>
                                    <div className="md-form my-0">
                                        {/* <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" /> */}
                                        {/* <MDBNavLink to=""><MDBIcon icon="home" /> {this.name} </MDBNavLink> */}
                                    </div>
                                </MDBFormInline>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>


                

            </>
        )
    }
}

